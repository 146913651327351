import { ENDPOINTS } from "config/Constants";
import { IAdminProduct, TSimpleProduct } from "interfaces/product";
import { Api } from "services/Api";

class ProductServiceClass {
  searchProduct = async (
    searchTerm: string
  ): Promise<TSimpleProduct[] | undefined> => {
    try {
      const fields =
        "id,name,product_id,price,options,quantity,image,model,weight_class,special";
      const headers = { "x-oc-image-dimension": "120x70" };
      const response = await Api.get(
        `${ENDPOINTS.GET_PRODUCT_BY_FIELDS}${fields}&limit=24&search=${searchTerm}`,
        { headers }
      );
      return response.data.data;
    } catch (error) {
      console.log("error in fetching products", error);
    }
  };

  fetchProductValues = async (
    id: string
  ): Promise<TSimpleProduct | undefined> => {
    try {
      const fields = "id,name,options";
      const response = await Api.get(
        ENDPOINTS.GET_PRODUCT_BY_FIELDS_BY_ID(id, fields)
      );
      return response.data.data;
    } catch (error) {
      console.log("error in fetching product for id " + id, error);
    }
  };

  fetchProductByCategory = async (
    cat_id: number
  ): Promise<IAdminProduct[] | undefined> => {
    try {
      const response = await Api.get(
        `${ENDPOINTS.PRODUCTS_BY_CATEGORY_ID}/${cat_id}`
      );
      return response.data.data;
    } catch (error) {
      console.log("error in fetching product " + error);
    }
  };

  updateProduct = async (product_id: number, payload: any): Promise<any> => {
    try {
      const response = await Api.put<any>(
        `${ENDPOINTS.PRODUCTS}/${product_id}`,
        payload
      );
      return response.data.data;
    } catch (error) {
      console.log("error in fetching product " + error);
    }
  };
}

const ProductService = new ProductServiceClass();
Object.freeze(ProductService);
export default ProductService;
